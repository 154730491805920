<template>
  <div>
    <b-card class="m-5">
      <div>
        <div class="logo-wrapper">
          <logo />
          <span style="font-size: 20px" class="ml-1 text-primary invoice-logo"
            ><b>SOCIO</b></span
          >
        </div>
        <br />
      </div>
      <div class="ml-1">
        <b><h3>ข้อมูลการติดต่อ</h3></b>
        บริษัท โซซิโอ จำกัด <br />
        <b> ที่อยู่: </b> เลขที่ 123 ห้อง NESP-314
        อาคารอำนวยการอุทยานวิทยาศาสตร์ หมู่ 16 ถ.กัลปพฤกษ์ ต.ในเมือง อ.เมือง
        จ.ขอนแก่น 40002 <br />
      </div>
      <div class="ml-1">
        <br />
        <b> วันทำการ :</b> จันทร์-ศุกร์ 09 :00-22:00, เสาร์-อาทิตย์ 09:00-20:00
        <br />
        <b>เบอร์โทร:</b> 0828490011 , 0828490044 <br />
        <!-- 0828490022 เครื่องพี่นน <br /> -->
      </div>
      <div align="center">
        <br />
        <img
          src="https://qr-official.line.me/sid/M/jwr7944i.png?shortenUrl=true"
        /><br />
        <a href="https://lin.ee/DvgEBrb"
          ><img
            src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
            alt="เพิ่มเพื่อน"
            height="36"
            border="0"
        /></a>
      </div>
    </b-card>
    <!-- <b-modal
      id="modal-no-backdrop"
      hide-backdrop
      ok-only
      no-close-on-backdrop
      content-class="shadow"
      title="Disabled Backdrop"
      hide-footer
      hide-header
      centered
      size="lg"
      v-model="show"
    >
      <div align="center">
     ที่อยู่:  บ.โซซิโอ จำกัด ห้อง NESP-314 เลขที่ 123 อาคารอำนวยการอุทยานวิทยาศาสตร์ หมู่16 ถ.กัลปพฤกษ์ ต.ในเมือง อ.เมือง จ.ขอนแก่น 40002
    วันทำการ : จันทร์-ศุกร์ 09 :00-22:00, เสาร์-อาทิตย์ 09:00-20:00
      </div>
    </b-modal> -->
  </div>
</template>
  
  <script>
import { BModal, BButton, VBModal, BAlert, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Logo from "@core/layouts/components/Logo.vue";
export default {
  components: {
    BCard,
    BButton,
    BModal,
    BAlert,
    Logo,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
};
</script>